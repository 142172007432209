import React from 'react';

import { t } from '@/utility/localization';

import { NASIO_PROFILE_IMG_SRC } from '../constants';

type Props = {
  cmProfileImageSrc: string;
};

const AvatarUnit = ({ cmProfileImageSrc }: Props) => {
  return (
    <div className="c-FAIQ-AvatarUnit flex flex-col gap-12 items-center">
      <div className="h-[60px] flex">
        <img
          src={NASIO_PROFILE_IMG_SRC}
          alt="nasio profile"
          className="h-56 w-56 rounded-[6px] rotate-[-4deg] z-1"
        />
        <img
          src={cmProfileImageSrc}
          alt="host profile"
          className="ml-[-8px] h-56 w-56 rounded-full rotate-[4deg] z-0"
        />
      </div>
      <p className="text-heading-sm font-semibold text-black">
        {t('were-here-to-help')}
      </p>
    </div>
  );
};

export default AvatarUnit;
