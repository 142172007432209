import style from './Conversation.module.scss';

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { MSG_TYPE } from '../constants';
import { TypeFaiqFaqs, TypeFaiqMsg, TypeReactionData } from '../types';
import ReceivedMsg from './ReceivedMsg';
import SentMsg from './SentMsg';
import TypingMsg from './TypingMsg';

type Props = {
  cmName: string;
  ctaProps: {
    buttonText: string;
    onClick: () => void;
    disabled?: boolean;
  };
  faqsMsgIdMap: Record<string, TypeFaiqFaqs>;
  handleDislikeMsg: (msgId: string) => void;
  handleFaqClick: (msg: string) => void;
  handleLikeMsg: (msgId: string) => void;
  handleUndoDislikeMsg: (msgId: string) => void;
  handleUndoLikeMsg: (msgId: string) => void;
  isFaiqResponseLoading: boolean;
  msgDataMap: Record<string, TypeFaiqMsg>;
  msgIdList: string[];
  reactionMsgIdMap: Record<string, TypeReactionData>;
  trackCtaClick: () => void;
};

const className = 'c-FAIQ-Conversation';

const Conversation = (props: Props) => {
  const {
    cmName,
    msgIdList,
    msgDataMap,
    reactionMsgIdMap,
    handleLikeMsg,
    handleDislikeMsg,
    handleUndoLikeMsg,
    handleUndoDislikeMsg,
    isFaiqResponseLoading,
    faqsMsgIdMap,
    handleFaqClick,
    ctaProps,
    trackCtaClick
  } = props;

  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom when messages change
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgIdList, msgDataMap, faqsMsgIdMap]);

  return (
    <div className={classNames(className, style[className])}>
      <div
        ref={chatContainerRef}
        className="max-h-[578px] px-12 pt-12 pb-32 md:max-h-500 md:px-24 md:pt-24 md:pb-48 rounded-16 overflow-y-auto backdrop-blur-2xl">
        <div className="flex flex-col gap-16">
          {msgIdList.map((msgId: string) => {
            const msgData = msgDataMap[msgId] as TypeFaiqMsg;
            const { text, type, isFetching, isIntroMsg } = msgData;

            const faqs = faqsMsgIdMap[msgId]?.faqs || [];
            const isFetchingFaqs = faqsMsgIdMap[msgId]?.isLoading || false;
            const reactionData = reactionMsgIdMap?.[msgId] || {};

            if (!text) return null;

            if (type === MSG_TYPE.RECEIVED) {
              return (
                <ReceivedMsg
                  msgId={msgId}
                  senderName={cmName}
                  key={msgId}
                  text={text}
                  isFetching={isFetching}
                  isIntroMsg={isIntroMsg}
                  faqs={faqs}
                  isFetchingFaqs={isFetchingFaqs}
                  reactionData={reactionData}
                  handleLikeMsg={handleLikeMsg}
                  handleDislikeMsg={handleDislikeMsg}
                  handleUndoLikeMsg={handleUndoLikeMsg}
                  handleUndoDislikeMsg={handleUndoDislikeMsg}
                  handleFaqClick={handleFaqClick}
                  isFaiqResponseLoading={isFaiqResponseLoading}
                  ctaProps={ctaProps}
                  trackCtaClick={trackCtaClick}
                />
              );
            }

            return <SentMsg key={msgId} text={text} />;
          })}

          {isFaiqResponseLoading && <TypingMsg senderName={cmName} />}
        </div>
      </div>
    </div>
  );
};

export default Conversation;
