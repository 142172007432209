import { FC, useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import Player from '@/components/common/LottiePlayer';

import useCountdown from '@/hooks/useCountdown';

import TimerRoundedBox from './TimerRoundedBox';

interface ChallengeInfo {
  canJoinAfterStart: boolean;
  endTime: Date;
  nextCheckpointStartTime: Date | null;
  startTime: Date;
}

interface ChallengeCountdownSectionProps {
  challengeInfo: ChallengeInfo;
  hasChallengeStarted: boolean;
}

interface CountdownState {
  date: Date;
  state: 'notStarted' | 'checkpoint' | 'challengeEnd';
}

const ChallengeCountdownSection: FC<ChallengeCountdownSectionProps> = ({
  challengeInfo,
  hasChallengeStarted
}) => {
  const {
    canJoinAfterStart,
    nextCheckpointStartTime,
    startTime,
    endTime
  } = challengeInfo || {};

  const [countdownState, setCountdownState] =
    useState<CountdownState | null>(null);

  useEffect(() => {
    const getCountdownState = (): CountdownState | null => {
      if (!hasChallengeStarted) {
        return {
          date: startTime,
          state: 'notStarted'
        };
      }

      if (hasChallengeStarted && canJoinAfterStart) {
        return {
          date: nextCheckpointStartTime ?? endTime,
          state: nextCheckpointStartTime ? 'checkpoint' : 'challengeEnd'
        };
      }

      return null;
    };

    setCountdownState(getCountdownState());
  }, [
    hasChallengeStarted,
    canJoinAfterStart,
    startTime,
    nextCheckpointStartTime,
    endTime
  ]);

  const renderHeader = (state: CountdownState['state']) => {
    switch (state) {
      case 'notStarted':
        return (
          <div className="mb-8 text-label-md font-medium text-npl-text-icon-on-dark-tertiary">
            {t('starts-in2')}
          </div>
        );
      case 'checkpoint':
        return (
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <Player
                className="h-20 w-20"
                src="/animation/happening-now-lottie.json"
                loop
                autoplay
              />
              <div>
                <div className="text-label-md font-medium text-npl-text-icon-on-dark-primary">
                  {t('challenge-is-happening')}
                </div>
                <div className="mb-8 text-label-md font-medium text-npl-text-icon-on-dark-tertiary">
                  {t('next-checkpoint-starts-in')}
                </div>
              </div>
            </div>
          </div>
        );
      case 'challengeEnd':
        return (
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <Player
                className="h-20 w-20"
                src="/animation/happening-now-lottie.json"
                loop
                autoplay
              />
              <div>
                <div className="text-label-md font-medium text-npl-text-icon-on-dark-primary">
                  {t('challenge-is-happening')}
                </div>
                <div className="mb-8 text-label-md font-medium text-npl-text-icon-on-dark-tertiary">
                  {t('challenge-ends-in')}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const { countdown } = useCountdown(countdownState?.date);

  const { days, hrs, mins, secs } = countdown || {};

  if (
    countdown?.days ||
    countdown?.hrs ||
    countdown?.mins ||
    countdown?.secs
  ) {
    return (
      <div className="mt-16 md:mt-24">
        {renderHeader(countdownState?.state)}
        <div className="flex gap-8">
          <TimerRoundedBox time={days} type={t('days')} />
          <TimerRoundedBox time={hrs} type={t('hours')} />
          <TimerRoundedBox time={mins} type={t('min')} />
          <TimerRoundedBox time={secs} type={t('sec')} />
        </div>
      </div>
    );
  }

  return null;
};

export default ChallengeCountdownSection;
