import style from './Conversation.module.scss';

import classNames from 'classnames';
import React from 'react';

import { t } from '@/utility/localization';

import Markdown from '@/components/common/Markdown';
import NPLButton from '@/components/npl/NPLButton';

import { TypeFaiqFaqItem, TypeReactionData } from '../types';
import Faqs from './Faqs';
import FeedbackRow from './FeedbackRow';
import ReceivedMsgHeader from './ReceivedMsgHeader';

type Props = {
  ctaProps: {
    buttonText: string;
    onClick: () => void;
    disabled?: boolean;
  };
  faqs: TypeFaiqFaqItem[];
  handleDislikeMsg: (msgId: string) => void;
  handleFaqClick: (msg: string) => void;
  handleLikeMsg: (msgId: string) => void;
  handleUndoDislikeMsg: (msgId: string) => void;
  handleUndoLikeMsg: (msgId: string) => void;
  msgId: string;
  reactionData: TypeReactionData;
  senderName: string;
  text: string;
  trackCtaClick: () => void;
  isFaiqResponseLoading?: boolean;
  isFetching?: boolean;
  isFetchingFaqs?: boolean;
  isIntroMsg?: boolean;
};

const ReceivedMsg = ({
  msgId,
  senderName,
  text,
  isFetching,
  isIntroMsg,
  isFaiqResponseLoading,
  faqs,
  isFetchingFaqs,
  reactionData,
  handleFaqClick,
  handleLikeMsg,
  handleDislikeMsg,
  handleUndoLikeMsg,
  handleUndoDislikeMsg,
  ctaProps,
  trackCtaClick
}: Props) => {
  return (
    <div className="c-FAIQ-ReceivedMsg rounded-b-16 rounded-tr-16 rounded-tl-4 p-16 shadow-npl-styles-button-shadow bg-white-default flex flex-col gap-16">
      <ReceivedMsgHeader senderName={senderName} />

      <Markdown
        className={classNames('text-para-sm text-dark-1b', {
          [style['markdown-container']]: isFetching
        })}>
        {text}
      </Markdown>

      {!isFetching && !isIntroMsg ? (
        <div className="animate-fadeIn-250">
          <NPLButton
            {...ctaProps}
            stretch
            size="lg"
            hierarchy="accent_primary"
            onClick={() => {
              trackCtaClick();
              ctaProps?.onClick?.();
            }}
          />
          <div className="mt-16">
            <FeedbackRow
              msgId={msgId}
              reactionData={reactionData}
              handleLikeMsg={handleLikeMsg}
              handleDislikeMsg={handleDislikeMsg}
              handleUndoLikeMsg={handleUndoLikeMsg}
              handleUndoDislikeMsg={handleUndoDislikeMsg}
            />
          </div>
        </div>
      ) : null}

      {!isFetching ? (
        <div className="animate-fadeIn-250">
          <div>
            <p className="text-label-md font-semibold text-dark-1b">
              {t('drop-a-question-or-just-pick-an-faq')}
            </p>
          </div>
          <div className="mt-16 pt-8 border-t-1 border-npl-separator-alpha">
            <Faqs
              faqs={faqs}
              loading={isFetchingFaqs}
              disabled={isFaiqResponseLoading}
              handleFaqClick={handleFaqClick}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReceivedMsg;
