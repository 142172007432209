import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import sessionStorageService from '@/utility/sessionStorageService';

export const getSessionId = () => {
  return sessionStorageService.getItem('sessionID');
};

const useUniqueSessionId = () => {
  const [sessionID, setSessionID] = useState(null);

  useEffect(() => {
    let storedSessionID = sessionStorageService.getItem('sessionID');

    if (!storedSessionID) {
      // If there's no session ID, generate a new one
      storedSessionID = uuidv4();
      sessionStorageService.setItem('sessionID', storedSessionID);
    }

    setSessionID(storedSessionID);
  }, []);

  return sessionID;
};

export default useUniqueSessionId;
