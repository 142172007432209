import axios from 'axios';

import { communityApiBasePath } from '@/utility/config';

import { TypeFaiqReactionPayload } from '@/features/faiq/types';

import { TypeAdminProtectedAxiosReqResponse } from './helpers/adminProtectedAxiosRequestTypes';

type TYPE_GENERAL_FAIQ_FILTERS = {
  communityId: string;
  productId: string;
  sessionId: string;
  learnerId?: string;
  productType?: string;
  threadId?: string;
};

type TYPE_TRACK_FAIQ_PRODUCT_PAGE_IMPRESSIONS_PAYLOAD = {
  productPageViewed: boolean;
} & TYPE_GENERAL_FAIQ_FILTERS;
export const trackFaiqProductPageImpressionAPI = async (
  payload: TYPE_TRACK_FAIQ_PRODUCT_PAGE_IMPRESSIONS_PAYLOAD
): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const apiPath = `${communityApiBasePath}/api/v1/assistant/ask`;
  return await axios.post(apiPath, payload);
};

type TYPE_TRACK_FAIQ_IMPRESSIONS_PAYLOAD = {
  faiqViewed: boolean;
} & TYPE_GENERAL_FAIQ_FILTERS;
export const trackFaiqImpressionAPI = async (
  payload: TYPE_TRACK_FAIQ_IMPRESSIONS_PAYLOAD
): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const apiPath = `${communityApiBasePath}/api/v1/assistant/ask`;
  return await axios.post(apiPath, payload);
};

type TYPE_TRACK_FAIQ_CTA_CLICK_PAYLOAD = {
  clickedJoin: boolean;
} & TYPE_GENERAL_FAIQ_FILTERS;
export const trackFaiqCtaClickAPI = async (
  payload: TYPE_TRACK_FAIQ_CTA_CLICK_PAYLOAD
): Promise<TypeAdminProtectedAxiosReqResponse> => {
  const apiPath = `${communityApiBasePath}/api/v1/assistant/ask`;
  return await axios.post(apiPath, payload);
};

export const getFaqsAPI = async (
  payload
): Promise<
  TypeAdminProtectedAxiosReqResponse<{
    assistantResponse: string;
    threadId: string;
  }>
> => {
  const apiPath = `${communityApiBasePath}/api/v1/assistant/ask/faq`;
  return await axios.post(apiPath, payload);
};

export const askFaiq = async (payload) => {
  const apiPath = `${communityApiBasePath}/api/v1/assistant/ask`;
  const response = await fetch(apiPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
    // signal: abortController.signal
  });

  return response;
};

export const reactToFaiqMsgAPI = async ({
  payload
}: {
  payload: TypeFaiqReactionPayload;
}) => {
  const apiPath = `${communityApiBasePath}/api/v1/assistant/reply/reaction`;
  return await axios.post(apiPath, payload);
};
