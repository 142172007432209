import { t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';

import ParticipantThumbnailStack from './ParticipantThumbnailStack';

const MINIMUM_PARTICIPANT_COUNT = 3;

const ShareUnit = ({
  openShareModal,
  participantCount,
  participantProfilePics
}) => {
  return (
    <div
      onClick={openShareModal}
      className="mt-32 space-y-16 rounded-16 bg-npl-neutral-light-solid-2 p-16 shadow-npl-styles-shadow-01 hover:cursor-pointer hover:bg-npl-neutral-light-solid-3">
      <div className="flex justify-between">
        <div className="space-y-16">
          {participantCount >= MINIMUM_PARTICIPANT_COUNT && (
            <div className="flex h-32 justify-between">
              <ParticipantThumbnailStack
                participants={participantProfilePics}
                size={40}
              />
            </div>
          )}
          <div className="space-y-4">
            <div className="text-heading-xs font-semibold">
              {t('together-is-more-fun')}
            </div>
            <div className="text-label-md text-npl-text-icon-on-light-surface-secondary">
              {t('challenges-are-fun-with-friends')}
            </div>
          </div>
        </div>
        <div className="flex h-40 w-40 min-w-40 items-center justify-center rounded-full border border-npl-neutral-light-solid-7">
          <Icon
            name="share-01"
            width={20}
            height={20}
            className="fill-npl-text-icon-on-light-surface-secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default ShareUnit;
