export const REACTION_ACTION = {
  ADD: 'add',
  REMOVE: 'remove'
};

export const TYPE_REACTION = {
  LIKE: 'like',
  DISLIKE: 'dislike'
};

export const MSG_TYPE = {
  SENT: 'sent',
  RECEIVED: 'received'
} as const;

export const NASIO_PROFILE_IMG_SRC =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasio/faiq/nasio-faiq-icon-4x.png';

export const FAIQ_CHAT_SESSION_STORAGE_KEY = 'faiq-chat-session-data';
