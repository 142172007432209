import { getCountryNameByCode } from '@/data/CoursePage/wisenetMapCountryList';
import { v4 as uuidv4 } from 'uuid';

import CookieService from '@/utility/cookieService';
import sessionStorageService from '@/utility/sessionStorageService';

import { FAIQ_CHAT_SESSION_STORAGE_KEY, MSG_TYPE } from './constants';
import { TypeFaiqMsg } from './types';

export const getCountryCode = () => {
  return (
    CookieService.get('country') ||
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
};

export const generateMsgId = uuidv4;

export const getFirstFaiqMsg = ({ productType, t }): TypeFaiqMsg => {
  const msgId = generateMsgId();
  let msg = '';

  const countryCode = getCountryCode();
  const countryName = getCountryNameByCode(countryCode);

  switch (productType) {
    case 'CHALLENGE': {
      if (countryName) {
        msg += t('hello-from-countryname', { countryName }) + ' ';
      }
      msg += t(
        'im-here-to-help-you-see-if-this-challenge-is-the-right-thing-for-you'
      );
      break;
    }
    default: {
      msg = t('hello-how-can-i-help-you-today');
      break;
    }
  }

  return {
    msgId,
    text: msg,
    type: MSG_TYPE.RECEIVED,
    isIntroMsg: true
  };
};

export const getFirstFaqMsgs = ({ productType, t }) => {
  switch (productType) {
    case 'CHALLENGE': {
      return [
        t('what-are-the-benefits-of-the-challenge'),
        t('when-is-the-challenge-starting'),
        t('how-much-does-it-cost-to-participate')
      ].map((msg) => ({
        original: msg,
        message: msg
      }));
    }
    default: {
      return [];
    }
  }
};

export const createSentMsg = (msg: string): TypeFaiqMsg => {
  const msgId = generateMsgId();
  return {
    msgId,
    text: msg,
    type: MSG_TYPE.SENT
  };
};

export const createReceivedMsg = (msg: string): TypeFaiqMsg => {
  const msgId = generateMsgId();
  return {
    msgId,
    text: msg,
    type: MSG_TYPE.RECEIVED
  };
};

export const getFaqsFromAiResp = (aiRespText: string) => {
  try {
    const cleanedAiRespText = aiRespText
      ?.replace('```json\n', '')
      ?.replace('```\n', '')
      ?.replace('\n```', '');

    const aiRespJson = JSON.parse(cleanedAiRespText);

    const faqs = Object.values(aiRespJson).map((faq) => ({
      message: faq,
      original: faq
    }));

    return faqs;
  } catch (e) {
    return [];
  }
};

export const getFaiqChatDataFromSessionStorage = ({ productId }) => {
  const strChatData = sessionStorageService.getItem(
    FAIQ_CHAT_SESSION_STORAGE_KEY
  );

  if (!strChatData) return;

  const chatData = JSON.parse(strChatData);

  if (!chatData?.[productId]) return;

  return chatData[productId];
};
