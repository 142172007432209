import { useEffect, useState } from 'react';

const useIsTabActive = (): boolean => {
  const [isTabActive, setIsTabActive] = useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange
      );
    };
  }, []);

  return isTabActive;
};

export default useIsTabActive;
