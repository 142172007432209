import React from 'react';

import Markdown from '@/components/common/Markdown';

type Props = {
  text: string;
};

const SentMsg = ({ text }: Props) => {
  return (
    <div className="c-FAIQ-SentMsg pl-40">
      <div className="rounded-b-16 p-16 shadow-npl-styles-button-shadow bg-npl-neutral-light-alpha-3 rounded-tl-16 rounded-tr-4">
        <Markdown className="text-para-sm text-dark-1b">{text}</Markdown>
      </div>
    </div>
  );
};

export default SentMsg;
