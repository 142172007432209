import React from 'react';

function TimerRoundedBox({ time, type }) {
  return (
    <div className=" flex w-full flex-col justify-center gap-4 rounded-12 bg-npl-transparent-white-25 p-8">
      <p className=" text-center text-heading-md font-semibold text-npl-text-icon-on-dark-primary">
        {time}
      </p>
      <p className=" text-center text-overline-sm font-medium uppercase text-npl-text-icon-on-dark-secondary">
        {type}
      </p>
    </div>
  );
}

export default TimerRoundedBox;
