import { whitelistedCommCodesForUserBehaviorTracking } from '@/utility/config';

import { trackSessionAnalytics } from '@/services/sessionAnalytics/sessionAnalyticsService';

export const isCommCodeWhitelisted = (commCode) => {
  return whitelistedCommCodesForUserBehaviorTracking?.includes(commCode);
};

export const trackSessionCheckoutVisit = async () => {
  const payload = {
    checkoutPage: {
      visited: true
    }
  };
  return await trackSessionAnalytics(payload);
};

export const getPageScrollDepthValues = () => {
  const scrollTop = window.scrollY; // Distance from the top of the page
  const windowHeight = window.innerHeight; // Height of the visible window
  const documentHeight = document.documentElement.scrollHeight; // Total height of the document

  // Calculate scroll depth as a percentage
  const scrollDepthPerc = (
    ((scrollTop + windowHeight) / documentHeight) *
    100
  ).toFixed(2); // Returns the percentage, rounded to two decimal places

  return {
    scrollDepth: scrollTop,
    scrollDepthPerc
  };
};
