import React from 'react';

import Icon from '@/components/npl/Icon';

import { TypeFaiqFaqItem } from '../types';

type Props = {
  disabled: boolean;
  faqs: TypeFaiqFaqItem[];
  handleFaqClick: (msg: string) => void;
  loading: boolean;
};

const Faqs = ({ faqs, handleFaqClick, disabled, loading }: Props) => {
  const loadingRow = (
    <div className="px-8 py-12">
      <div className="h-40 animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
    </div>
  );
  return (
    <div className="c-FAIQ-Faqs">
      {loading ? (
        <div>
          {loadingRow}
          {loadingRow}
          {loadingRow}
        </div>
      ) : (
        <div className="animate-fadeIn-250">
          {faqs.map((faq, index) => {
            return (
              <button
                key={index}
                className="w-full flex justify-between items-center gap-4 px-8 py-12 rounded-8 hover:bg-npl-neutral-light-alpha-2"
                disabled={disabled}
                onClick={() => {
                  if (disabled) return;
                  handleFaqClick(faq.message);
                }}>
                <p className="text-body-sm text-dark-1b text-left">
                  {faq.message}
                </p>
                <Icon
                  name="send-magic-01"
                  width={20}
                  height={20}
                  fill="#1B1B18"
                  fillOpacity={0.35}
                />
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Faqs;
