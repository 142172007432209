/**
 * Objective of this file is to track the follwing info
 * {
 * communityCode: string,
 * communityObjectId: string,
 * entityObjectId: string,
 * entityType: string,
 * timeSpentInSecond: number,
 * scrollDepth: number,
 * numberOfVisit: number,
 * }
 */
import { useEffect, useMemo, useState } from 'react';

import { trackSessionAnalytics } from '@/services/sessionAnalytics/sessionAnalyticsService';

import { useAuthContext } from '@/contexts/AuthContext';

import useIsTabActive from '@/hooks/useIsTabActive';
import useUniqueSessionId from '@/hooks/useUniqueSessionId';

import { UPDATE_TYPES } from '../constants';
import { getPageScrollDepthValues, isCommCodeWhitelisted } from '../utils';

const useLandingPageUserBehaviorTracking = (props) => {
  useUniqueSessionId();
  const { user } = useAuthContext();
  const isTabActive = useIsTabActive();
  const [viewStartTime] = useState(Date.now());
  const { communityCode, communityObjectId, entityObjectId, entityType } =
    props;
  const isWhitelisted = isCommCodeWhitelisted(communityCode);

  const commonTrackingPayload = useMemo(() => {
    return {
      updateType: UPDATE_TYPES.KEY_UPDATE_LANDING_PAGE,
      communityCode,
      communityObjectId,
      entityObjectId,
      entityType
    };
  }, [communityCode, communityObjectId, entityObjectId, entityType]);

  useEffect(() => {
    if (!isWhitelisted) return;

    const email = user?.email;
    if (email) {
      const payload = {
        updateType: UPDATE_TYPES.KEY_UPDATE_SESSION_INFO,
        data: {
          email
        }
      };
      trackSessionAnalytics(payload);
    }
  }, [isWhitelisted, user]);

  // on mount, send { communityCode, communityId, entityId, entityType }
  useEffect(() => {
    if (!isWhitelisted) return;
    const payload = {
      ...commonTrackingPayload,
      data: {}
    };
    trackSessionAnalytics(payload);
  }, [isWhitelisted, commonTrackingPayload]);

  // Every 5s, save scrollDepth and timeSpentInSecond to server
  useEffect(() => {
    if (!isWhitelisted) return;

    const intervalId = setInterval(() => {
      const timeSpentInSecond = Math.round(
        (Date.now() - viewStartTime) / 1000
      );
      const { scrollDepth, scrollDepthPerc } = getPageScrollDepthValues();
      const payload = {
        ...commonTrackingPayload,
        data: {
          timeSpentInSecond,
          scrollDepth,
          scrollDepthPercentage: scrollDepthPerc
        }
      };
      trackSessionAnalytics(payload);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [isWhitelisted, isTabActive, commonTrackingPayload, viewStartTime]);

  return {};
};

export default useLandingPageUserBehaviorTracking;
