import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/npl/Icon';

import { TypeReactionData } from '../types';

type Props = {
  handleDislikeMsg: (msgId: string) => void;
  handleLikeMsg: (msgId: string) => void;
  handleUndoDislikeMsg: (msgId: string) => void;
  handleUndoLikeMsg: (msgId: string) => void;
  msgId: string;
  reactionData: TypeReactionData;
};
const FeedbackRow = ({
  msgId,
  reactionData,
  handleLikeMsg,
  handleDislikeMsg,
  handleUndoLikeMsg,
  handleUndoDislikeMsg
}: Props) => {
  const { isLoading, reaction } = reactionData || {};
  return (
    <div className="c-FAIQ-FeedbackRow flex justify-end gap-8">
      <button
        disabled={isLoading}
        className={classNames(
          'p-4 rounded-full hover:bg-npl-neutral-light-alpha-4',
          {
            'bg-npl-neutral-light-alpha-4': reaction?.like
          }
        )}
        onClick={() => {
          if (reaction?.like) {
            handleUndoLikeMsg(msgId);
          } else {
            handleLikeMsg(msgId);
          }
        }}>
        <Icon
          name="thumbs-up"
          width={20}
          height={20}
          fill="#1B1B18"
          fillOpacity={0.35}
        />
      </button>
      <button
        disabled={isLoading}
        className={classNames(
          'p-4 rounded-full hover:bg-npl-neutral-light-alpha-4',
          {
            'bg-npl-neutral-light-alpha-4': reaction?.dislike
          }
        )}
        onClick={() => {
          if (reaction?.dislike) {
            handleUndoDislikeMsg(msgId);
          } else {
            handleDislikeMsg(msgId);
          }
        }}>
        <Icon
          name="thumbs-down"
          width={20}
          height={20}
          fill="#1B1B18"
          fillOpacity={0.35}
        />
      </button>
    </div>
  );
};

export default FeedbackRow;
