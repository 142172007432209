import React, { useMemo } from 'react';

import {
  formatNumber,
  getAppLinkForChallenge,
  getAppLinkForChallenges,
  openInNewTab
} from '@/utility/helpers';
import { t } from '@/utility/localization';
import { getCommunityPageTabRoute } from '@/utility/routesHelper';

import { useAuthContext } from '@/contexts/AuthContext';

import BackAndShareBtnBar from '@/components/common/BackAndShareBtnBar/BackAndShareBtnBar';
import NextImage from '@/components/common/NextImage';
import { TABS_ID } from '@/components/features/CommunityPage/constants';
import NPLButton from '@/components/npl/NPLButton';

import { getPriceText } from '@/pages/portal/utils';

import ChallengeCountdownSection from './ChallengeCountdownSection';
import ChallengeCoverVideo from './ChallengeCoverVideo';
import ParticipantThumbnailStack from './ParticipantThumbnailStack';

function HeroBanner({
  challengeInfo,
  communityInfo,
  isFetchingChallengeDetails,
  isCommunityAdmin,
  hasChallengeStarted,
  isCommunityMember,
  isPendingApproval,
  hasChallengeEnded,
  hasEnrolledToCommunity,
  handleGoToManageChallenge,
  renderDaysDateRange,
  initJoinChallenge,
  isParticipant,
  handleJoinAsMember,
  isJoiningChallenge,
  discountedCodeData,
  isLoggedIn,
  priceDetails
}) {
  const { user } = useAuthContext();
  const { canJoinAfterStart, participantCount, participantProfilePics } =
    challengeInfo || {};
  const challengeAmount =
    priceDetails?.localAmount ?? priceDetails?.amount;
  const challengeCurrency =
    priceDetails?.localCurrency ?? priceDetails?.currency;
  const isPaidAccess = challengeInfo?.access === 'paid';
  const appLink = getAppLinkForChallenge(
    communityInfo,
    challengeInfo,
    user
      ? {
          email: user.email
        }
      : null
  );
  const communityPageChallengeTabRoute = getCommunityPageTabRoute({
    communitySlug: communityInfo?.slug || '',
    tabId: TABS_ID.CHALLENGES,
    fullUrl: true
  });
  const appLinkChallenges = getAppLinkForChallenges(
    communityInfo,
    communityPageChallengeTabRoute,
    user
      ? {
          email: user.email
        }
      : null
  );

  const discountedChallengePrice = useMemo(() => {
    if (discountedCodeData) {
      const { type, value } = discountedCodeData;
      if (type === 'percentage') {
        return parseFloat(
          Number(challengeAmount * (1 - value / 100)).toFixed(2)
        );
      }
    }

    return null;
  }, [discountedCodeData, challengeAmount]);

  const priceText = useMemo(() => {
    return getPriceText(
      challengeAmount,
      discountedChallengePrice,
      challengeCurrency,
      challengeInfo?.pricingConfig?.priceType,
      true
    );
  }, [
    challengeAmount,
    discountedChallengePrice,
    challengeCurrency,
    challengeInfo?.pricingConfig?.priceType
  ]);

  const renderCTAButton = () => {
    if (isCommunityAdmin) {
      return (
        <NPLButton
          size="xl"
          dataTestId="manage-challenge"
          hierarchy="neutral_primary"
          buttonText={t('manage-challenge')}
          darkMode
          onClick={handleGoToManageChallenge}
          loading={isJoiningChallenge}
          tailIcon="arrow-right"
          stretch
        />
      );
    }
    if (isParticipant) {
      return (
        <NPLButton
          size="xl"
          dataTestId="open-in-app"
          hierarchy="neutral_primary"
          buttonText={t('open-in-app')}
          onClick={() => openInNewTab(appLink)}
          darkMode
          tailIcon="arrow-right"
          stretch
        />
      );
    }

    if (isPendingApproval) {
      return (
        <NPLButton
          disabled
          size="xl"
          dataTestId="pending-approval"
          hierarchy="accent_primary"
          buttonText={t('pending-approval')}
          stretch
        />
      );
    }

    if ((hasChallengeStarted && !canJoinAfterStart) || hasChallengeEnded) {
      if (isCommunityMember || hasEnrolledToCommunity) {
        return (
          <NPLButton
            size="xl"
            dataTestId="go-to-community"
            hierarchy="neutral_primary"
            buttonText={t('go-to-community')}
            onClick={() => openInNewTab(appLinkChallenges)}
            darkMode
            tailIcon="arrow-right"
            stretch
          />
        );
      }
      return (
        <NPLButton
          size="xl"
          dataTestId="join-community"
          hierarchy="accent_primary"
          buttonText={t('join-community')}
          loading={isJoiningChallenge}
          onClick={handleJoinAsMember}
          stretch
        />
      );
    }

    if (isPendingApproval) {
      return (
        <NPLButton
          disabled
          size="xl"
          dataTestId="pending-approval"
          hierarchy="accent_primary"
          buttonText={t('pending-approval')}
          stretch
        />
      );
    }

    return (
      <NPLButton
        size="xl"
        dataTestId="join-challenge"
        hierarchy="accent_primary"
        buttonText={t('join-challenge-1')}
        loading={isJoiningChallenge || isFetchingChallengeDetails}
        onClick={initJoinChallenge}
        disabled={challengeInfo?.isRegistrationClosed}
        stretch
      />
    );
  };

  const renderChallengeDetails = () => {
    // isManager
    if (isCommunityAdmin) {
      return (
        <div>
          <div className="text-heading-xs font-medium text-npl-text-icon-on-dark-primary">
            {t('you-have-manage-access')}
          </div>
          <div className="text-body-sm text-npl-text-icon-on-dark-secondary">
            {t('manage-the-challenge-in-the-community-portal')}
          </div>
        </div>
      );
    }
    // if user is a participants
    if (isParticipant) {
      return (
        <div>
          <div className="text-heading-xs font-medium text-npl-text-icon-on-dark-primary">
            {t('you-are-a-participant')}
          </div>
          <div className="text-body-sm text-npl-text-icon-on-dark-secondary">
            {t('open-the-nas-app-to-participate-in-the-challenge')}
          </div>
        </div>
      );
    }

    if (hasChallengeEnded) {
      return (
        <div>
          <div className="text-heading-xs font-medium text-npl-text-icon-on-dark-primary">
            {t('challenge-has-ended')}
          </div>
          <div className="text-body-sm text-npl-text-icon-on-dark-secondary">
            {isCommunityMember
              ? t('go-to-community-portal-to-join-the-challenges')
              : t(
                  'join-the-community-to-participate-in-future-challenges'
                )}
          </div>
        </div>
      );
    }
    // if Challenge started and not a participant
    if (hasChallengeStarted && !canJoinAfterStart) {
      return (
        <div>
          <div className="text-heading-xs font-medium text-npl-text-icon-on-dark-primary">
            {t('registration-closed')}
          </div>
          <div className="text-body-sm text-npl-text-icon-on-dark-secondary ">
            {isCommunityMember
              ? t('go-to-community-portal-to-join-the-challenges')
              : t(
                  'join-the-community-to-participate-in-future-challenges'
                )}
          </div>
        </div>
      );
    }
    // default
    if (isPaidAccess) {
      return (
        <div>
          <div className="text-label-md font-medium text-npl-text-icon-on-dark-tertiary">
            {t('participation-fee')}
          </div>
          <div className="mt-[5px] text-body-sm text-npl-text-icon-on-dark-primary">
            {priceText}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="text-label-md font-medium text-npl-text-icon-on-dark-tertiary">
            {t('participation-fee')}
          </div>
          <div className="text-body-sm text-npl-text-icon-on-dark-secondary">
            {t('free')}
          </div>
        </div>
      );
    }
  };

  const hideParticipantCount =
    challengeInfo?.additionalSettings?.hideParticipantCountOnLP;

  const isAlwaysOnChallenge = challengeInfo?.challengeType === 'ALWAYS_ON';

  return (
    <div className="w-full max-w-[880px]">
      <div className="mb-24">
        <BackAndShareBtnBar
          entityTitle={challengeInfo?.title}
          entityType="challenge"
          darkMode={true}
          communityImageSrc={communityInfo?.profileImage}
          communityLink={communityInfo?.slug}
          communityTitle={communityInfo?.title}
          isLoggedIn={isLoggedIn}
        />
      </div>
      <div className="flex justify-between space-x-40">
        <div className="flex w-full flex-col">
          <div className="mb-12 text-heading-xl font-semibold text-npl-text-icon-on-dark-primary">
            {challengeInfo?.title}
          </div>
          {!isAlwaysOnChallenge && renderDaysDateRange()}
          {participantCount >= 3 && !hideParticipantCount && (
            <div className="mb-16 flex items-center space-x-8">
              <ParticipantThumbnailStack
                participants={participantProfilePics}
                size={20}
                className=" !border-1 border-npl-transparent-white-50"
                showYou={false}
              />
              <span className="text-label-md font-medium text-npl-text-icon-on-dark-secondary">{`${formatNumber(
                participantCount
              )} ${
                participantCount > 1
                  ? t('participants2')
                  : t('participant')
              }`}</span>
            </div>
          )}
          {challengeInfo.coverVideo?.mediaData?.mp4Link ? (
            <div className="md:hidden">
              <ChallengeCoverVideo
                videoAssets={{
                  mp4: challengeInfo.coverVideo.mediaData.mp4Link,
                  hls: challengeInfo.coverVideo.mediaData.video?.hlsLink,
                  dash: challengeInfo.coverVideo.mediaData.video?.link
                }}
                videoLink={challengeInfo.coverVideo.mediaData.mp4Link}
                alt={`${challengeInfo.title} - Challenge banner image`}
                thumbnail={
                  challengeInfo.cover ||
                  challengeInfo.coverVideo.mediaData.thumbnail
                }
                isThumbnailStretched={challengeInfo.cover}
              />
            </div>
          ) : (
            <div className="aspect-h-9 aspect-w-16 relative flex md:hidden">
              <NextImage
                alt="challenge banner image"
                mobileImgProps={{
                  src: challengeInfo?.cover,
                  layout: 'fill',
                  objectFit: 'cover',
                  priority: true
                }}
                className="rounded-12"
              />
            </div>
          )}
          <div className="hidden w-full border-b-1 border-npl-transparent-white-25 md:block"></div>
          <div className="mb-20 mt-16">
            <div className="flex flex-col gap-[5px]">
              <div className="text-heading-xs font-medium text-npl-text-icon-on-dark-primary">
                {renderChallengeDetails()}
              </div>
            </div>
          </div>
          <div className="block w-full border-b-1 border-npl-transparent-white-25 md:hidden"></div>
          <div className="hidden md:block">{renderCTAButton()}</div>
        </div>
        <div className="hidden flex-shrink-0 flex-grow-0 md:flex">
          {challengeInfo.coverVideo?.mediaData?.mp4Link ? (
            <div className="w-[345px] min-w-[345px]">
              <ChallengeCoverVideo
                videoAssets={{
                  mp4: challengeInfo.coverVideo.mediaData.mp4Link,
                  hls: challengeInfo.coverVideo.mediaData.video?.hlsLink,
                  dash: challengeInfo.coverVideo.mediaData.video?.link
                }}
                videoLink={challengeInfo.coverVideo.mediaData.mp4Link}
                alt={`${challengeInfo.title} - Challenge banner image`}
                thumbnail={
                  challengeInfo.cover ||
                  challengeInfo.coverVideo.mediaData.thumbnail
                }
                isThumbnailStretched={challengeInfo.cover}
              />
            </div>
          ) : (
            <div
              className={
                'relative min-w-[140px] overflow-hidden rounded-12'
              }>
              <NextImage
                mobileImgProps={{
                  src: challengeInfo?.cover,
                  layout: 'fixed',
                  width: 345,
                  height: 194,
                  objectFit: 'cover'
                }}
                className="flex-shrink-0 flex-grow-0 rounded-xl"
              />
            </div>
          )}
        </div>
      </div>
      {!hasChallengeEnded && (
        <ChallengeCountdownSection
          challengeInfo={challengeInfo}
          hasChallengeStarted={hasChallengeStarted}
        />
      )}
      <div className="mt-28 block w-full md:hidden">
        {renderCTAButton()}
      </div>
    </div>
  );
}

export default HeroBanner;
