import axios from 'axios';

import config from '@/utility/config';

import { getSessionId } from '@/hooks/useUniqueSessionId';

// import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';

/**
 * payload = {
 *    sessionId,
 *    email,
 *    communityObjectId,
 *    landingPage: {
 *      sections: {
 *        name, timeSpentInSeconds, viewCount
 *      }[]
 *    },
 *    checkoutPage: {
 *      visited,
 *    }
 * }
 */
export const trackSessionAnalytics = async (payload = {}) => {
  try {
    const sessionId = getSessionId();
    if (!sessionId) return;

    // const resp = await adminProtectedAxiosRequest.post(
    const resp = await axios.post(`${config.analyticsEndpoint}/track`, {
      sessionId,
      ...payload
    });

    return resp;
  } catch (e) {
    console.warn('>> in catch of trackSessionAnalytics', e, e.message);
  }
};
