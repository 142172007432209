import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Modal from '@/components/common/Modal';
import PopOverModal from '@/components/common/PopOverModal';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { CommunityInfoType } from '../types';
import CheckpointListDisplay from './CheckpointListDisplay';

export const DEFAULT_CHECKPOINT_PAGE_NO = 1;
export const CHECKPOINT_PAGE_SIZE = 20;

type CheckpointPreviewModalProps = {
  challengeId: string;
  checkpointCount: number;
  communityId: string;
  communityInfo: CommunityInfoType;
  handleJoinAsMember: () => void;
  initJoinChallenge: () => void;
  isChallengeJoinable: boolean;
  isCommunityJoinable: boolean;
  onClose: () => void;
};

const CheckpointPreviewModal: React.FC<CheckpointPreviewModalProps> = ({
  onClose,
  checkpointCount,
  challengeId,
  communityId,
  communityInfo,
  isCommunityJoinable,
  isChallengeJoinable,
  handleJoinAsMember,
  initJoinChallenge
}) => {
  const { isGtEqMd } = useWindowWidthContext();

  const RenderedModal = isGtEqMd ? Modal : PopOverModal;

  return (
    <RenderedModal
      showCloseIcon={false}
      open
      onClose={onClose}
      customInnerClass="!p-0">
      <div className="flex h-full max-h-[500px] min-h-0 w-full flex-1 flex-col">
        <div className="flex items-center justify-between border-b-1 border-npl-neutral-light-solid-4 py-12 pl-24 pr-16">
          <div className="flex items-center space-x-12">
            <Icon
              name="flag-01"
              className="fill-npl-yellow-light-solid-11"
            />
            <span className="text-heading-xs font-semibold text-npl-text-icon-on-light-surface-primary">
              {`${checkpointCount} ${t('checkpoints')}`}
            </span>
          </div>
          <NPLButton
            hierarchy="plain"
            rounded
            size="sm"
            leadIcon="x-close"
            onClick={onClose}
          />
        </div>
        <div className="flex flex-1 flex-col overflow-auto p-24">
          <CheckpointListDisplay
            checkpointCount={checkpointCount}
            challengeId={challengeId}
            communityId={communityId}
            communityInfo={communityInfo}
          />
        </div>
        {isChallengeJoinable && (
          <div className="flex justify-end border-t-1 border-npl-neutral-light-solid-4 px-24 py-12">
            <NPLButton
              hierarchy="accent_primary"
              size="md"
              stretch
              onClick={() => {
                isCommunityJoinable
                  ? handleJoinAsMember()
                  : initJoinChallenge();
                onClose();
              }}
              buttonText={t('join-challenge-1')}
            />
          </div>
        )}
      </div>
    </RenderedModal>
  );
};

export default CheckpointPreviewModal;
