import React, { useEffect, useRef, useState } from 'react';

import { trackSessionAnalytics } from '@/services/sessionAnalytics/sessionAnalyticsService';

import useIsTabActive from '@/hooks/useIsTabActive';
import useUniqueSessionId from '@/hooks/useUniqueSessionId';

import { UPDATE_TYPES } from '../constants';
import { isCommCodeWhitelisted } from '../utils';

const ViewTrackingWrapper = ({
  name,
  communityId,
  communityCode,
  entityId,
  entityType,
  children
}) => {
  useUniqueSessionId();
  const isTabActive = useIsTabActive();
  const [timeSpent, setTimeSpent] = useState(0); // Time spent in the viewport
  const [viewCount, setViewCount] = useState(0); // Number of times the element was viewed
  const observerRef = useRef(null);
  const elementRef = useRef(null);
  const timeInViewRef = useRef(null); // Keeps track of when the element enters the viewport

  const intervalRef = useRef(null);

  const isWhitelisted = isCommCodeWhitelisted(communityCode);

  useEffect(() => {
    if (!isWhitelisted) return;

    // Function to be called when the element enters or exits the viewport
    const observerCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && isTabActive) {
        // Element enters the viewport
        setViewCount((prevCount) => prevCount + 1); // Increment view count
        timeInViewRef.current = Date.now(); // Record the time it enters

        // Start tracking time when the component is in the viewport
        intervalRef.current = setInterval(() => {
          setTimeSpent((prevTime) => prevTime + 5000);
          timeInViewRef.current = Date.now(); // Record the time it enters
        }, 5000);
      } else {
        // Element exits the viewport
        if (timeInViewRef.current) {
          const timeSpentInView = Date.now() - timeInViewRef.current;
          setTimeSpent((prevTime) => prevTime + timeSpentInView); // Update the time spent
          timeInViewRef.current = null; // Reset the entry time
        }

        clearInterval(intervalRef.current);
      }
    };

    // Set up the IntersectionObserver
    observerRef.current = new IntersectionObserver(observerCallback, {
      threshold: 0.1 // 10% of the element is in the viewport
    });

    if (elementRef.current) {
      observerRef.current.observe(elementRef.current); // Start observing the element
    }

    return () => {
      // Clean up the observer when the component unmounts
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      clearInterval(intervalRef.current);
    };
  }, [isWhitelisted, isTabActive]);

  useEffect(() => {
    if (!isWhitelisted) return;
    if (viewCount || timeSpent) {
      const payload = {
        updateType: UPDATE_TYPES.KEY_UPDATE_LANDING_PAGE_SECTION,
        communityCode,
        communityObjectId: communityId,
        entityObjectId: entityId,
        entityType,
        sectionName: name,
        data: {
          timeSpentInSecond: timeSpent ? Math.round(timeSpent / 1000) : 0,
          numberOfVisit: viewCount
        }
      };

      trackSessionAnalytics(payload);
    }
  }, [
    isWhitelisted,
    communityCode,
    communityId,
    entityId,
    entityType,
    name,
    timeSpent,
    viewCount
  ]);

  return (
    <div ref={elementRef}>
      {React.cloneElement(children, { timeSpent, viewCount })}
    </div>
  );
};

export default ViewTrackingWrapper;
