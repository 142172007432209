import React from 'react';

import { t } from '@/utility/localization';

import NextImage from '@/components/common/NextImage';

import { NASIO_PROFILE_IMG_SRC } from '../constants';

type Props = {
  senderName: string;
};

const ReceivedMsgHeader = ({ senderName }: Props) => {
  return (
    <div className="c-FAIQ-ReceivedMsgHeader flex gap-12">
      <NextImage
        mobileImgProps={{
          src: NASIO_PROFILE_IMG_SRC,
          width: 28,
          height: 28,
          alt: 'avatar'
        }}
        className="object-cover w-28 h-28 aspect-square rounded-[6px]"
        alt="nasio"
      />
      <div className="flex gap-4 items-center">
        <p className="text-label-lg font-medium text-dark-1b">Nas.io</p>
        <p className="text-label-md font-medium text-dark-1b text-opacity-35">
          ·
        </p>
        <p className="text-label-md font-medium text-dark-1b text-opacity-35">
          {senderName || t('community-manager')}
        </p>
      </div>
    </div>
  );
};

export default ReceivedMsgHeader;
