const KEY_UPDATE_SESSION_INFO = 'track-session-info';
const KEY_UPDATE_LANDING_PAGE = 'track-landing-page';
const KEY_UPDATE_LANDING_PAGE_SECTION = 'track-landing-page-section';
const KEY_UPDATE_SESSION_CHECKOUT = 'track-session-checkout';

export const UPDATE_TYPES = {
  KEY_UPDATE_SESSION_INFO,
  KEY_UPDATE_LANDING_PAGE,
  KEY_UPDATE_LANDING_PAGE_SECTION,
  KEY_UPDATE_SESSION_CHECKOUT
};

export const TRACKING_CHALLENGE_LANDING_PAGE = {
  SECTION: {
    ABOUT_CHALLENGE: 'about-challenge',
    CHECKPOINT_PREVIEW: 'checkpoint-preview',
    FAIQ: 'faiq',
    CHALLENGE_INSTRUCTIONS: 'challenge-instructions',
    CHALLENGE_REWARDS: 'challenge-rewards'
  }
};
