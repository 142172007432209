import { useState } from 'react';

import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import ShareModal from '@/components/common/ShareModal/ShareModal';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { getChallengePublicPageUrl } from '../utils';
import CheckpointPreviewCard from './CheckpointPreviewCard';
import CheckpointPreviewModal from './CheckpointPreviewModal';
import ShareUnit from './ShareUnit';

const MINIMUM_CHECKPOINT_COUNT = 3;

const CheckpointPreview = ({
  challengeTitle,
  checkpointCount,
  checkpointsBrief,
  communitySlug,
  challengeSlug,
  participantCount,
  participantProfilePics,
  challengeId,
  communityId,
  communityInfo,
  initJoinChallenge,
  handleJoinAsMember,
  isChallengeJoinable,
  isCommunityJoinable
}) => {
  const [showShareChallengeModal, setShowShareChallengeModal] =
    useState(false);
  const [isCheckpointPreviewModalOpen, setIsCheckpointPreviewModalOpen] =
    useState(false);
  const openShareModal = () => {
    setShowShareChallengeModal(true);
  };
  const { isGtEqMd } = useWindowWidthContext();
  const closeShareModal = () => setShowShareChallengeModal(false);

  const renderCheckpoints = (checkpoints) => {
    return checkpoints.map((checkpoint, index) => (
      <CheckpointPreviewCard
        key={`${index}-CheckpointPreviewCard`}
        checkpoint={checkpoint}
        hasDivider={index < checkpoints.length - 1}
        isBlurDivider={
          index === 1 && checkpointCount > MINIMUM_CHECKPOINT_COUNT
        }
        isPreview={true}
      />
    ));
  };

  return (
    <div className="mx-auto flex flex-col justify-between gap-x-80 gap-y-24 border-t border-npl-neutral-light-solid-4 py-40 md:flex-row">
      <div className="flex max-w-[240px] flex-1 gap-x-12">
        <Icon
          name="flag-01"
          width={24}
          height={24}
          className="fill-npl-yellow-light-solid-11"
        />
        <p className="text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary">
          {`${checkpointCount} ${
            checkpointCount > 1 ? t('checkpoints') : t('checkpoint')
          }`}
        </p>
      </div>
      <div className="w-full flex-1">
        {renderCheckpoints(checkpointsBrief)}
        {checkpointCount > MINIMUM_CHECKPOINT_COUNT && (
          <div className="mt-16">
            <NPLButton
              buttonText={t('see-all')}
              size="sm"
              stretch={!isGtEqMd}
              hierarchy="outline"
              onClick={() => setIsCheckpointPreviewModalOpen(true)}
            />
          </div>
        )}
        <ShareUnit
          openShareModal={openShareModal}
          participantCount={participantCount}
          participantProfilePics={participantProfilePics}
        />
        {showShareChallengeModal && (
          <ShareModal
            shareUrl={getChallengePublicPageUrl(
              communitySlug,
              challengeSlug
            )}
            title={t('share-the-challenge')}
            shareText={challengeTitle}
            open={showShareChallengeModal}
            onClose={closeShareModal}
            label={t('challenge-public-page-link')}
          />
        )}
      </div>
      {isCheckpointPreviewModalOpen && (
        <CheckpointPreviewModal
          onClose={() => setIsCheckpointPreviewModalOpen(false)}
          challengeId={challengeId}
          communityId={communityId}
          communityInfo={communityInfo}
          checkpointCount={checkpointCount}
          initJoinChallenge={initJoinChallenge}
          handleJoinAsMember={handleJoinAsMember}
          isChallengeJoinable={isChallengeJoinable}
          isCommunityJoinable={isCommunityJoinable}
        />
      )}
    </div>
  );
};

export default CheckpointPreview;
