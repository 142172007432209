import React, { FormEvent, useCallback, useEffect, useState } from 'react';

import { t } from '@/utility/localization';
import sessionStorageService from '@/utility/sessionStorageService';

import {
  askFaiq,
  getFaqsAPI,
  reactToFaiqMsgAPI,
  trackFaiqCtaClickAPI,
  trackFaiqImpressionAPI,
  trackFaiqProductPageImpressionAPI
} from '@/services/faiqService';

import { useAuthContext } from '@/contexts/AuthContext';

import { showToast } from '@/components/common/ToastContainer';

import useLocale from '@/hooks/useLocale';
import useUniqueSessionId from '@/hooks/useUniqueSessionId';

import {
  FAIQ_CHAT_SESSION_STORAGE_KEY,
  REACTION_ACTION,
  TYPE_REACTION
} from '../constants';
import { TypeFaiqMsg } from '../types';
import {
  createReceivedMsg,
  createSentMsg,
  getCountryCode,
  getFaiqChatDataFromSessionStorage,
  getFaqsFromAiResp,
  getFirstFaiqMsg,
  getFirstFaqMsgs
} from '../utils';

const useFaiq = ({ communityId, productId, productName, productType }) => {
  const sessionId = useUniqueSessionId();
  const [threadId, setThreadId] = useState('');
  const [faqThreadId, setFaqThreadId] = useState('');

  const [inputMsg, setInputMsg] = useState('');

  const [messages, setMessages] = useState([]);
  const [msgIdList, setMsgIdList] = useState([]);
  const [msgDataMap, setMsgDataMap] = useState({});
  const [faqsMsgIdMap, setFaqsMsgIdMap] = useState({});
  const [reactionMsgIdMap, setReactionMsgIdMap] = useState({});

  const [isFaiqResponseLoading, setIsFaiqResponseLoading] =
    useState(false);

  const [isImpressionTracked, setIsImpressionTracked] = useState(false);
  const [isCtaClickTracked, setIsCtaClickTracked] = useState(false);
  const [isPageviewTracked, setIsPageviewTracked] = useState(false);

  const { currentLocale } = useLocale();
  const { user } = useAuthContext();
  const learnerId = user?.learner?._id;

  const countryId = getCountryCode();

  const saveChatInSessionStorage = useCallback(() => {
    const chatData = {
      sessionId,
      threadId,
      faqThreadId,
      msgIdList,
      msgDataMap,
      faqsMsgIdMap,
      reactionMsgIdMap
    };
    const productChatData = { [productId]: chatData };

    sessionStorageService.setItem(
      FAIQ_CHAT_SESSION_STORAGE_KEY,
      JSON.stringify(productChatData)
    );
  }, [
    productId,
    faqThreadId,
    faqsMsgIdMap,
    msgDataMap,
    msgIdList,
    reactionMsgIdMap,
    sessionId,
    threadId
  ]);

  const setStateFromSessionStorage = useCallback(() => {
    const chatData = getFaiqChatDataFromSessionStorage({ productId });
    if (!chatData) return;

    setThreadId(chatData.threadId);
    setFaqThreadId(chatData.faqThreadId);
    setMsgIdList(chatData.msgIdList);
    setMsgDataMap(chatData.msgDataMap);
    setFaqsMsgIdMap(chatData.faqsMsgIdMap);
    setReactionMsgIdMap(chatData.reactionMsgIdMap);
  }, [productId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputMsg(e.target.value);
  };

  const appendMessage = (message: TypeFaiqMsg) => {
    setMessages((prev) => [...prev, message]);
    setMsgIdList((prev) => [...prev, message.msgId]);
    setMsgDataMap((prev) => ({ ...prev, [message.msgId]: message }));
  };

  const generateAiResponse = async ({ message, receivingMsg }) => {
    const payload = {
      sessionId,
      question: message.text,
      threadId: threadId,
      communityId,
      productId,
      productName,
      productType,
      ...(learnerId ? { learnerId } : {}),
      isFAQ: false,
      currentLocale,
      countryId
    };

    try {
      setIsFaiqResponseLoading(true);
      const response = await askFaiq(payload);
      const reader = response.body
        ?.pipeThrough(new TextDecoderStream())
        .getReader();

      let fullData = '';
      appendMessage({ ...receivingMsg, isFetching: true });
      const receivingMsgId = receivingMsg.msgId;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value: textToken, done: readerDone } = await reader.read();
        if (readerDone) break;

        // threadId: is used to set the threadId for the next request and to keep the context
        if (textToken && textToken.includes('threadId:')) {
          const threadIdInfo = textToken.split(':')[1];
          setThreadId(threadIdInfo);
          continue;
        }

        if (textToken) {
          fullData += textToken;
          setMsgDataMap((prev) => ({
            ...prev,
            [receivingMsgId]: {
              ...receivingMsg,
              text: fullData,
              isFetching: true
            }
          }));

          setIsFaiqResponseLoading(false);
        }
      }

      setMsgDataMap((prev) => ({
        ...prev,
        [receivingMsgId]: { ...prev[receivingMsgId], isFetching: false }
      }));
    } catch (e) {
      // Handle Abort error exeception.
      // Set error states
    } finally {
      setIsFaiqResponseLoading(false);
    }
  };

  const generateFaqs = async ({ message, receivingMsgId }) => {
    const payload = {
      sessionId,
      question: message.text,
      threadId: faqThreadId,
      communityId,
      productId,
      productName,
      productType,
      ...(learnerId ? { learnerId } : {}),
      currentLocale,
      countryId
    };

    try {
      setFaqsMsgIdMap((prev) => ({
        ...prev,
        [receivingMsgId]: { faqs: [], isLoading: true }
      }));

      const { data, error } = await getFaqsAPI(payload);

      if (error) {
        throw new Error(error);
      }

      const { threadId, assistantResponse } = data.data;
      const faqs = getFaqsFromAiResp(assistantResponse);

      // Set state
      setFaqThreadId(threadId);
      setFaqsMsgIdMap((prev) => ({
        ...prev,
        [receivingMsgId]: { faqs, isLoading: false }
      }));
    } catch (e) {
      setFaqsMsgIdMap((prev) => ({
        ...prev,
        [receivingMsgId]: { faqs: [], isLoading: false }
      }));
    }
  };

  const generateFaqsByStream = async ({ message, receivingMsgId }) => {
    const payload = {
      sessionId,
      question: message.text,
      threadId: faqThreadId,
      communityId,
      productId,
      productName,
      productType,
      ...(learnerId ? { learnerId } : {}),
      isFAQ: true,
      currentLocale,
      countryId
    };

    try {
      setFaqsMsgIdMap((prev) => ({
        ...prev,
        [receivingMsgId]: { faqs: [], isLoading: true }
      }));

      const response = await askFaiq(payload);
      const reader = response.body
        ?.pipeThrough(new TextDecoderStream())
        .getReader();

      let fullData = '';

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value: textToken, done: readerDone } = await reader.read();
        if (readerDone) break;

        // threadId: is used to set the threadId for the next request and to keep the context
        if (textToken && textToken.includes('threadId:')) {
          const threadIdInfo = textToken.split(':')[1];
          setFaqThreadId(threadIdInfo);
          continue;
        }

        if (textToken) {
          fullData += textToken;
        }
      }

      const faqs = getFaqsFromAiResp(fullData);

      setFaqsMsgIdMap((prev) => ({
        ...prev,
        [receivingMsgId]: { faqs, isLoading: false }
      }));
    } catch (e) {
      // Handle Abort error exeception.
      // Set error states
    }
  };

  const handleSendMsg = (message: string) => {
    // validate
    if (!message) return;

    const sentMsg = createSentMsg(message);
    appendMessage(sentMsg);

    const receivingMsg = createReceivedMsg('');
    const receivingMsgId = receivingMsg.msgId;

    generateAiResponse({ message: sentMsg, receivingMsg });
    generateFaqs({ message: sentMsg, receivingMsgId });

    setInputMsg('');
  };

  const handleSendMsgSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // validate
    if (!inputMsg) return;

    handleSendMsg(inputMsg);
  };

  const handleFaqClick = (faqMsg: string) => {
    handleSendMsg(faqMsg);
  };

  const setLoadingReaction = ({ msgId }) => {
    setReactionMsgIdMap((prev) => {
      return {
        ...prev,
        [msgId]: {
          ...(prev[msgId] ? prev[msgId] : {}),
          isLoading: true
        }
      };
    });
  };

  const handleReactToMsg = async ({ action, reaction, msgId }) => {
    setLoadingReaction({ msgId });

    const payload = {
      communityId,
      productId,
      productType,
      sessionId,
      threadId,
      ...(learnerId ? { learnerId } : {}),
      messageData: {
        messageId: msgId,
        text: msgDataMap?.[msgId]?.text || ''
      },
      reaction,
      action
    };

    await reactToFaiqMsgAPI({ payload });

    setReactionMsgIdMap((prev) => {
      const isAddingLike =
        action === REACTION_ACTION.ADD && reaction === TYPE_REACTION.LIKE;
      const isAddingDisike =
        action === REACTION_ACTION.ADD &&
        reaction === TYPE_REACTION.DISLIKE;

      const updatedData = {
        isLoading: false,
        reaction: {
          ...(prev[msgId]?.reaction || {}),
          like: isAddingLike,
          dislike: isAddingDisike
        }
      };
      return {
        ...prev,
        [msgId]: updatedData
      };
    });

    showToast({ text: t('feedback-recorded') });
  };

  const handleLikeMsg = async (msgId: string) =>
    await handleReactToMsg({
      action: REACTION_ACTION.ADD,
      reaction: TYPE_REACTION.LIKE,
      msgId
    });

  const handleDislikeMsg = async (msgId: string) =>
    await handleReactToMsg({
      action: REACTION_ACTION.ADD,
      reaction: TYPE_REACTION.DISLIKE,
      msgId
    });

  const handleUndoLikeMsg = async (msgId: string) =>
    await handleReactToMsg({
      action: REACTION_ACTION.REMOVE,
      reaction: TYPE_REACTION.LIKE,
      msgId
    });

  const handleUndoDislikeMsg = async (msgId: string) =>
    await handleReactToMsg({
      action: REACTION_ACTION.REMOVE,
      reaction: TYPE_REACTION.DISLIKE,
      msgId
    });

  const trackCtaClick = async () => {
    if (isCtaClickTracked) return;
    const payload = {
      clickedJoin: true,
      communityId,
      productId,
      sessionId,
      productType,
      ...(threadId ? { threadId } : {}),
      ...(learnerId ? { learnerId } : {})
    };

    const result = await trackFaiqCtaClickAPI(payload);
    if (!result?.error) {
      setIsCtaClickTracked(true);
    }
  };

  const trackFaiqImpression = async () => {
    if (isImpressionTracked) return;
    const payload = {
      faiqViewed: true,
      communityId,
      productId,
      sessionId,
      productType,
      ...(threadId ? { threadId } : {}),
      ...(learnerId ? { learnerId } : {})
    };

    const result = await trackFaiqImpressionAPI(payload);
    if (!result?.error) {
      setIsImpressionTracked(true);
    }
  };

  const trackFaiqProductPageViewed = async () => {
    if (isPageviewTracked) return;
    const payload = {
      productPageViewed: true,
      communityId,
      productId,
      sessionId,
      productType,
      ...(threadId ? { threadId } : {}),
      ...(learnerId ? { learnerId } : {})
    };

    const result = await trackFaiqProductPageImpressionAPI(payload);
    if (!result?.error) {
      setIsPageviewTracked(true);
    }
  };

  const handleReset = () => {
    setMessages([]);
    setMsgIdList([]);
    setMsgDataMap({});
    setFaqsMsgIdMap({});
    setReactionMsgIdMap({});
  };

  // First message
  useEffect(() => {
    // set from session storage if data is available
    const chatDataInStorage = getFaiqChatDataFromSessionStorage({
      productId
    });
    if (chatDataInStorage) {
      setStateFromSessionStorage();
      return;
    }

    // Generate first message
    const firstFaiqMsg = getFirstFaiqMsg({ productType, t });
    const msgId = firstFaiqMsg.msgId;
    appendMessage(firstFaiqMsg);

    // Generate first FAQ message set
    const faqs = getFirstFaqMsgs({ productType, t });
    setFaqsMsgIdMap({
      [msgId]: { faqs, isLoading: false }
    });
  }, [productId, productType, setStateFromSessionStorage]);

  // Save data to session storage
  useEffect(() => {
    saveChatInSessionStorage();
  }, [saveChatInSessionStorage]);

  return {
    sessionId,
    messages,
    msgIdList,
    msgDataMap,
    faqsMsgIdMap,
    reactionMsgIdMap,
    isFaiqResponseLoading,
    generateFaqsByStream,

    // form
    inputMsg,
    handleInputChange,

    // handle send msg
    handleSendMsg,
    handleSendMsgSubmit,
    handleReset,
    handleFaqClick,

    // Reaction Fns
    handleLikeMsg,
    handleDislikeMsg,
    handleUndoLikeMsg,
    handleUndoDislikeMsg,

    // tracking
    trackFaiqProductPageViewed,
    trackFaiqImpression,
    trackCtaClick
  };
};

export default useFaiq;
