import React from 'react';

import ReceivedMsgHeader from './ReceivedMsgHeader';
import TypingAnimation from './TypingAnimation';

type Props = {
  senderName: string;
};

const TypingMsg = ({ senderName }: Props) => {
  return (
    <div className="c-FAIQ-TypingMsg rounded-b-16 rounded-tr-16 rounded-tl-4 p-16 shadow-npl-styles-button-shadow bg-white-default flex flex-col gap-16">
      <ReceivedMsgHeader senderName={senderName} />
      <TypingAnimation />
    </div>
  );
};

export default TypingMsg;
