import React from 'react';

const TypingAnimation = () => {
  const renderDot = ({ key, delayMs }) => {
    return (
      <div
        key={key}
        className="w-8 h-8 rounded-full bg-[#1B1B1859] animate-typing"
        style={{ animationDelay: `${delayMs}ms` }}></div>
    );
  };

  return (
    <div className="c-FAIQ-TypingAnimation h-24 p-4 flex gap-4 items-end">
      {[0, 150, 300].map((delayMs, key) => renderDot({ key, delayMs }))}
    </div>
  );
};

export default TypingAnimation;
