import classNames from 'classnames';
import React, { useEffect } from 'react';

import { t } from '@/utility/localization';

import BlurredBackground from '@/features/EventPublicPage/components/BlurredBackground/BlurredBackground';

import Icon from '@/components/npl/Icon';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';

import useFaiq from '../hooks/useFaiq';
import AvatarUnit from './AvatarUnit';
import Conversation from './Conversation';

type Props = {
  bgCoverImgSrc: string;
  cmName: string;
  cmProfileImageSrc: string;
  communityId: string;
  ctaProps: {
    buttonText: string;
    onClick: () => void;
    disabled?: boolean;
  };
  productId: string;
  productName: string;
  productType: string;
};

const Faiq = ({
  communityId,
  cmProfileImageSrc,
  bgCoverImgSrc,
  productId,
  productName,
  productType,
  cmName,
  ctaProps
}: Props) => {
  const {
    sessionId,
    msgIdList,
    msgDataMap,
    faqsMsgIdMap,
    reactionMsgIdMap,
    isFaiqResponseLoading,

    // form
    inputMsg,
    handleInputChange,

    // handle send msg
    handleSendMsgSubmit,
    handleFaqClick,

    // Reaction Fns
    handleLikeMsg,
    handleDislikeMsg,
    handleUndoLikeMsg,
    handleUndoDislikeMsg,

    // tracking
    trackFaiqProductPageViewed,
    trackFaiqImpression,
    trackCtaClick
  } = useFaiq({
    communityId,
    productId,
    productName,
    productType
  });

  const [faiqRef, isIntersectingFaiq] = useIntersectionObserver({
    threshold: 0.1
  });

  // Track impression when Faiq is in view
  useEffect(() => {
    if (isIntersectingFaiq) {
      trackFaiqImpression();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersectingFaiq]);

  // track page view
  useEffect(() => {
    if (sessionId) trackFaiqProductPageViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <div
      ref={faiqRef as React.RefObject<HTMLDivElement>}
      className="c-Faiq relative overflow-hidden">
      <BlurredBackground
        bannerImg={bgCoverImgSrc}
        alt="challenge banner image"
        customClassName="absolute"
        blurValue="blur-md"
      />
      <div className="py-40 px-16 md:py-80 md:mx-auto md:w-[560px] flex flex-col gap-24">
        {/* Intro Header Nas.io + CM */}
        <AvatarUnit cmProfileImageSrc={cmProfileImageSrc} />
        {/* Conversation */}
        <Conversation
          cmName={cmName}
          msgIdList={msgIdList}
          msgDataMap={msgDataMap}
          handleLikeMsg={handleLikeMsg}
          handleDislikeMsg={handleDislikeMsg}
          handleUndoLikeMsg={handleUndoLikeMsg}
          handleUndoDislikeMsg={handleUndoDislikeMsg}
          isFaiqResponseLoading={isFaiqResponseLoading}
          faqsMsgIdMap={faqsMsgIdMap}
          handleFaqClick={handleFaqClick}
          reactionMsgIdMap={reactionMsgIdMap}
          ctaProps={ctaProps}
          trackCtaClick={trackCtaClick}
        />
        {/* Send Message */}
        <div>
          <form
            onSubmit={handleSendMsgSubmit}
            className="flex justify-between gap-8 rounded-100 border-1 border-npl-neutral-light-alpha-7 bg-white-default px-8 py-12 shadow-npl-styles-shadow-01">
            <input
              className="px-8 flex-1 outline-none"
              value={inputMsg}
              onChange={handleInputChange}
              placeholder={t('ai-ask-us-anything')}
            />

            <button type="submit" className="py-4 pl-4 pr-8">
              <Icon
                name="send-magic-01"
                width={16}
                height={16}
                className={classNames({
                  'fill-text-dark-1b': !inputMsg,
                  'fill-npl-yellow-light-alpha-11': inputMsg
                })}
                fillOpacity={!inputMsg ? 0.35 : 1}
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Faiq;
